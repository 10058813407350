import { Button, Typography, Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import WeightButtons from "./RadioButtons/WeightButtons";
import { useState } from "react";

const weightSize = [
  { id: "40Kg", label: "< 40Kg", value: "less than 40Kg" },
  { id: "40-60Kg", label: "40 - 60Kg", value: "40 - 60Kg" },
  { id: "60-80Kg", label: "60 - 80Kg", value: "60 - 80Kg" },
  { id: "80-100Kg", label: "80 - 100Kg", value: "80 - 100Kg" },
  { id: "100Kg", label: "> 100Kg", value: "greater than 100Kg" },
];

type FormValues = {
  weight: string;
};

const WeightStep = ({
  updateStep,
  previousStep,
  formData,
  setFormData,
  step,
}: any) => {
  const methods = useForm<FormValues>({
    defaultValues: { weight: formData.weightStep.weight },
    mode: "onChange",
  });

  const [clickButton, setClickButton] = useState(false);

  const { handleSubmit } = methods;

  const formSubmit = (data: any) => {
    setFormData({
      ...formData,
      weightStep: data.target.defaultValue,
    });
    setClickButton(true);

    setTimeout(() => {
      updateStep();
    }, 500);
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box
          sx={{
            height: { xs: "400px", lg: "502px" },
            width: { xs: "100%", sm: "100%", lg: "100%" },

            display: "flex",
            background: "#F2F2F2",
            borderTopRightRadius: { xs: 0, lg: "18px" },
            borderBottomRightRadius: { xs: "18px", lg: "18px" },
            borderBottomLeftRadius: { xs: 18, lg: "0" },
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: "1",
          }}
        >
          <Box
            sx={{
              padding: { xs: "0", lg: "25px 0 0% 0 " },
              display: { xs: "none", lg: "flex" },
              justifyContent: { xs: "space-between", lg: "space-between" },
              zIndex: "5",
            }}
          >
            <Button
              onClick={previousStep}
              sx={{
                height: "2.7rem",
                borderRadius: "50px",
                width: { xs: "50%", lg: "7rem" },
                color: "#FF3A18",

                background: "transparent",
                ":hover": {
                  bgcolor: "transparent",
                  color: "#FF3A18",
                },
              }}
              type="submit"
              variant="text"
            >
              <Typography
                sx={{
                  fontFamily: "Manrope",
                  textTransform: "capitalize",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Back
              </Typography>
            </Button>
          </Box>
          <Box
            className="transitions"
            sx={{
              position: "relative",
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box>
              <WeightButtons
                name="weight"
                options={weightSize}
                step={step}
                onClickButton={formSubmit}
              />

              <Box
                sx={{
                  padding: { xs: "25px 0 0% 0", lg: "0" },
                  display: { xs: "flex", lg: "none" },
                  justifyContent: {
                    xs: "space-between",
                    lg: "space-between",
                  },
                  zIndex: "5",
                }}
              >
                <Button
                  onClick={previousStep}
                  sx={{
                    height: "2.7rem",
                    borderRadius: "50px",
                    width: { xs: "0%", lg: "7rem" },
                    color: "#FF3A18",

                    background: "transparent",
                    ":hover": {
                      bgcolor: "transparent",
                      color: "#FF3A18",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

export default WeightStep;
