import { Typography, Box, Grid } from "@mui/material";
import Beer from "../../assets/images/beers.png";
import Wine from "../../assets/images/wines.png";
import Arrow from "../../assets/images/Frame.svg";

import { Button } from "@mui/joy";

const Confirm = () => {
  const navigateHome = () => {
    window.location.href =
    "https://www.aware.org.za/";
  }
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: { xs: "100%", lg: "100%" },

          flexDirection: "column",
          justifyContent: { xs: "flex-start", lg: "flex-start" },
          borderTopRightRadius: { xs: 0, lg: "18px" },
          borderBottomRightRadius: { xs: "18px", lg: "18px" },
          borderBottomLeftRadius: { xs: 18, lg: "0" },
          background: "#F2F2F2",
          margin: { xs: "0 0 0 0", lg: "0" },
          paddingX: "10px",
          justifyContentent: "center",
        }}
      >
        <Box
          className="transitions"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "column" },
            gap: "20px",
            height: "100%",
            padding: { xs: "20px 0 ", lg: "20px" },
          }}
        >
          <Box sx={{ paddingLeft: { xs: "10px", lg: "52px" } }}>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "30px",
                fontWeight: "700",
                color: "#464852",
              }}
            >
              How to understand units
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "20px",
                fontWeight: "700",
                color: "#FF3A18",
              }}
            >
              What 14 units look like
            </Typography>
          </Box>

          <img src={Beer} alt="/" loading="lazy" />

          <Box
            sx={{
              display: "flex",
              gap: "25px",
              paddingLeft: { xs: "10px", lg: "52px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: "700",
                color: "#464852",
              }}
            >
              6 Pints of Beer
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "16px",
                fontWeight: "400",
                color: "rgba(70, 72, 82, 0.50)",
              }}
            >
              Based on 568ml 4% ABV{" "}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: "700",
                color: "#FF3A18",
                textAlign: "center",
                textTransform: "lowercase",
              }}
            >
              Or
            </Typography>
          </Box>

          <img src={Wine} alt="/" loading="lazy" />

          <Box
            sx={{
              display: "flex",
              gap: "25px",
              paddingLeft: { xs: "10px", lg: "52px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: "700",
                color: "#464852",
              }}
            >
              6 Glasses of Wine{" "}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope",
                fontSize: "16px",
                fontWeight: "400",
                color: "rgba(70, 72, 82, 0.50)",
              }}
            >
              Based on 175ml 13.5% ABV{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", lg: "none" },
            flexDirection: "row",
            gap: { xs: "0", lg: "0" },
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <Button
            sx={{
              background: "#FF3A18",
              borderRadius: "77.4px",
              color: "#FFF",
              ":hover": {
                background: "#FF3A18",
                color: "#FFF",
                border: "1px solid #FF3A18",
              },
            }}
            onClick={navigateHome}
            type="submit"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                gap: "20px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ textTransform: "none" }}>
                  Back to the site{" "}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  flexGrow: "3",
                  backgroundClip: "#FF3A18",
                }}
              >
                <img src={Arrow} alt="/" />
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Confirm;
