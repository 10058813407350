import { Button, Box, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";
import { Modal, ModalDialog, Sheet } from "@mui/joy";
import Arrow from "../../assets/images/Frame.svg";
import Logos from "../../assets/images/logoei.svg";

import "../../styles/transition.css";

type FormValues = {
  age: string;
};

const AgeGate = ({ updateStep, formData, setFormData }: any) => {
  const [errorMessage, setErrorMessage] = useState("");

  const methods = useForm<FormValues>({
    defaultValues: { age: formData.ageStep.date },
    mode: 'onChange'
  });

  const { handleSubmit } = methods;

  const [age, setAge]: any = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [timer, setTimer] = useState(5);

  const redirector = () => {
    setOpenModal(true);
    const counter = setInterval(() => {
      setTimer((curCount) => curCount - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(counter);
      window.location.href =
        "https://aware-org-alt1.webflow.io/focus-areas/no-to-underage-drinking";
    }, 5000);
  };

  const formSubmit = (data: any) => {
    if (age) {
      if (age >= 0 && age < 18) {
        redirector();
      } else if (age < 0) {
        setErrorMessage("Enter valid age!");
      } else {
        updateStep();
      }
    }

    setFormData({
      ...formData,
      ageStep: age,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              height: { xs: "400px", lg: "502px" },
              width: { xs: "100%", sm: "100%", lg: "550px" },
              border: "2px solid #FFF",
              borderTopRightRadius: { xs: 0, lg: "18px" },
              borderBottomRightRadius: { xs: "18px", lg: "18px" },
              borderBottomLeftRadius: { xs: 18, lg: "0" },
              display: "flex",
              background: "#F2F2F2",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",

                height: "100%",
              }}
            >
              <Box
                className="transitions"
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: { xs: "100px", lg: "160px" },
                }}
              >
                <Box>
                  <input
                    className="text-fields"
                    placeholder="Enter your age"
                    type="number"
                    value={age || ""}
                    onChange={(e) => setAge(e.target.value)}
                  />
                  <Box className="">
                    {errorMessage && (
                      <Typography
                        sx={{
                          fontFamily: "Manrope",
                          color: "red",
                          fontWeight: "700",
                          fontSize: "13px",
                          textAlign: "center",
                          paddingTop: "10px",
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginTop: "0%",
                    padding: "0 0 0% 1%",
                    alignSelf: "center",
                  }}
                >
                  <Box>
                    <Modal
                      aria-labelledby="modal-title"
                      aria-describedby="modal-desc"
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        padding: { xs: "0 20px 0 20px", lg: "0" },
                      }}
                    >
                      <ModalDialog
                        layout="fullscreen"
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.9)",
                          height: "100%",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 0,
                        }}
                      >
                        <Sheet
                          variant="plain"
                          sx={{
                            maxWidth: 500,
                            borderRadius: "18px",
                            p: 3,
                            boxShadow: "lg",
                            backgroundColor: "#363D49",
                            gap: "25px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              paddingBottom: "40px",
                            }}
                          >
                            <img src={Logos} alt="/" />
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Manrope",
                                color: "#FFF",
                                fontSize: { xs: "25px", lg: "30px" },
                                fontWeight: "700",
                                textAlign: "center",
                                paddingBottom: "40px",
                              }}
                            >
                              You have to be 18 years or older to access this
                              tool.
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              fontFamily: "Manrope",
                              color: "#FFF",
                              fontSize: { xs: "14px", lg: "18px" },
                              fontWeight: "500",
                              paddingBottom: "40px",
                              textAlign: "center",
                            }}
                          >
                            You will now be redirected to our resource page.
                          </Typography>
                          <Typography
                            sx={{
                              paddingBottom: "40px",
                              textAlign: "center",
                              fontSize: { xs: "14px", lg: "18px" },
                            }}
                          >
                            <a
                              href="https://aware.org.za/ourwork/no-to-underage-drinking/"
                              style={{
                                fontFamily: "Manrope",
                                color: "#FF3A18",
                                fontWeight: "400",
                                textAlign: "center",
                              }}
                            >
                              https://aware.org.za/no-to-underage-drinking/
                            </a>
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Manrope",
                              color: "#FF3A18",
                              fontSize: { xs: "14px", lg: "18px" },
                              fontWeight: "400",
                              textAlign: "center",
                              paddingBottom: "40px",
                            }}
                          >
                            {timer > 0 ? timer : 0}s{" "}
                          </Typography>
                        </Sheet>
                      </ModalDialog>
                    </Modal>
                  </Box>

                  <Button
                    variant="contained"
                    sx={{
                      background: "#FF3A18",
                      borderRadius: "77.4px",
                      color: "#FFF",
                      height: "2.7rem",
                      padding: "5px 10px",
                      width: "150px",
                      boxShadow: "none",

                      ":hover": {
                        width: "150px",
                        background: "#FF3A18",
                        borderRadius: "77.4px",
                        height: "2.7rem",
                        padding: "5px 10px",
                        color: "#FFF",
                        border: "1px solid #FF3A18",
                        boxShadow: "none",
                      },
                    }}
                    disabled={age == null || age == ""}
                    type="submit"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            textTransform: "capitalize",
                          }}
                        >
                          Start
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignSelf: "center",
                          width: "100%",
                        }}
                      >
                        <img src={Arrow} alt="/" />
                      </Box>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default AgeGate;
