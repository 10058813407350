import { Box, Container, Typography } from "@mui/material";
import ImageContainer from "./ImageContainer";
import AwareLogo from "../../assets/images/newlogo.svg";
import bigLogo from "../../assets/images/hugeLogo.svg";
import smallLogo from "../../assets/images/hugesmalldev.svg";
import ResultsStep from "../CalculatorForm/ResultsStep";

const PageLayout = ({
  children,
  progressValue,
  step,
  content,
  formData,
  previousStep,
  updateStep,
}: any) => {
  return (
    <>
      <Container
        disableGutters={step === 10 ? true : false}
        maxWidth={step === 10 ? false : "lg"}
        sx={{
          maxWidth: "1440px",
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            left:
              step === 10 ? { xs: "0", lg: "0" } : { xs: "0", lg: "0%" },
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: step == 10 ? "column" : "row",
              paddingBottom: "20px",
              paddingTop: "20px",
              justifyContent: "flex-start",
              alignItems: step == 10 ? "flex-start" : "center",
              gap: "30px",
              paddingLeft: step == 10 ? "20px" : "0",
            }}
          >
            <Box>
              <img
                src={AwareLogo}
                alt="aware logo"
                style={{
                  width: "139px",
                  height: "39px",
                  justifyContent: step == 10 ? "flex-start" : "center",
                }}
              />
            </Box>
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              <Typography
                sx={{
                  alignSelf: step == 0 ? "flex-start" : "center",
                  fontFamily: "Manrope",
                  fontSize: "32px",
                  fontWeight: "700",
                  color: "#FFF",
                }}
              >
                Alcohol Impact Calculator
              </Typography>
            </Box>
          </Box>
        </Box>
        {step == 10 ? (
          <ResultsStep
            updateStep={updateStep}
            previousStep={previousStep}
            step={step}
          />
        ) : (

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              padding: { xs: "0", lg: "50px 0 50px 0" },
              
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "50%" },
              }}
            >
              <ImageContainer
                progressValue={progressValue}
                step={step}
                content={content}
                formData={formData}
                className="transitions"
              />
            </Box>

            <Box
              sx={{
                width: { xs: "100%", lg: "50%" },
              }}
            >
              {children}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            zIndex: "-1",
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            src={bigLogo}
            alt="/"
            style={{
              position: "absolute",
              bottom: "0px",
              alignSelf: "center",
              height: "150px",
              width: "1196px",
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "-12px",
            zIndex: "-1",
            justifyContent: "center",
            width: { xs: "90%", lg: "1196px" },
            height: { xs: "0", lg: "120px" },
            display: { xs: "block", lg: "none" },
            justifyItems: "center",
          }}
        >
          <img
            src={smallLogo}
            alt="/"
            style={{
              position: "absolute",
              bottom: "auto",
              alignSelf: "center",
              width: "100%",
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default PageLayout;
