import { useEffect, useState } from "react";

import { Button, Typography, Box } from "@mui/material";

import { useForm, FormProvider } from "react-hook-form";

import DrinkSizeStepRadioButtons from "./RadioButtons/DrinkSizeStepRadioButtons";

let drinkSizeBeerType = [
  { id: "1", label: "330ml", value: "330ml" },
  { id: "2", label: "440ml", value: "440ml" },
  { id: "3", label: "750ml", value: "750ml" },
  { id: "4", label: "1L", value: "1000ml" },
];
let drinkSizeWineType = [
  { id: "1", label: "125ml - Normal Glass", value: "125ml" },
  { id: "2", label: "250ml - Large Glass", value: "250ml" },
  { id: "3", label: "750ml - Bottle", value: "750.1ml" },
];
let drinkSizeHardLiquorType = [
  { id: "1", label: "Shot-25ml", value: "25ml" },
  { id: "2", label: "Double Shot - 50ml", value: "50ml" },
  { id: "3", label: "Shot(mixed drink) - 300ml", value: "300ml" },
  { id: "4", label: "Double Shot(mixed drink) - 60ml", value: "60ml" },
  { id: "5", label: "Bottle of spirits- 750ml", value: "750.2ml" },
];
let drinkSizeHomeBrewType = [
  { id: "1", label: "½ litre", value: "0.5 litre" },
  { id: "2", label: "1  litre", value: "1 litre" },
  { id: "3", label: "2 litres", value: "2 litres" },
];

type FormValues = {
  drinkSize: string;
};

const DrinkSizeStep = ({
  updateStep,
  previousStep,
  formData,
  setFormData,
  step,
}: any) => {
  const [drinkType, setDrinkType] = useState("");
  const methods = useForm<FormValues>({
    defaultValues: { drinkSize: formData.drinkSizeStep.drinkSize },
    mode: "onChange",
  });

  const [clickButton, setClickButton] = useState(false);

  const { handleSubmit } = methods;

  const formSubmit = (data: any) => {
    setFormData({
      ...formData,
      drinkSizeStep: data.target.defaultValue,
    });
    setClickButton(true);
    setTimeout(() => {
      updateStep();
    }, 500);
  };

  useEffect(() => {
    setDrinkType(formData.choiceStep);
  }, [drinkType, formData.choiceStep]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              height: { xs: "400px", lg: "502px" },
              width: { xs: "100%", lg: "100%" },

              display: "flex",
              background: "#F2F2F2",
              borderTopRightRadius: { xs: 0, lg: "18px" },
              borderBottomRightRadius: { xs: "18px", lg: "18px" },
              borderBottomLeftRadius: { xs: 18, lg: "0" },
              flexDirection: "column",
              padding: { xs: "0 10px 0 10px", lg: "0" },
              justifyContent: "flex-start",
              flexGrow: "1",
            }}
          >
            <Box
              sx={{
                padding: { xs: "0 0px 0 ", lg: "25px 0 0 0 " },
                margin: { xs: "-0 0 0 0", lg: "0 0 0 0" },
                display: { xs: "none", lg: "flex" },
                justifyContent: { xs: "space-between", lg: "flex-start" },
                zIndex: "5",
              }}
            >
              <Button
                onClick={previousStep}
                sx={{
                  height: "2.7rem",
                  borderRadius: "50px",
                  width: { xs: "50%", lg: "7rem" },
                  color: "#FF3A18",

                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                type="submit"
                variant="text"
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Back
                </Typography>
              </Button>
            </Box>

            <Box
              className="transitions"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <DrinkSizeStepRadioButtons
                name="drinkSize"
                options={
                  drinkType === "Beer"
                    ? drinkSizeBeerType
                    : drinkType === "Cider"
                    ? drinkSizeBeerType
                    : drinkType === "Wine"
                    ? drinkSizeWineType
                    : drinkType === "Home Brew"
                    ? drinkSizeHomeBrewType
                    : drinkSizeHardLiquorType
                }
                step={step}
                onClickButton={formSubmit}
              />
              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },
                  width: "100%",
                  position: "absolute",
                  bottom: "15px",
                }}
              >
                <Button
                  onClick={previousStep}
                  sx={{
                    height: "2.7rem",
                    borderRadius: "50px",
                    width: { xs: "0%", lg: "7rem" },
                    color: "#FF3A18",

                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: "50%",
                display: "flex",
                justifyContent: "space-between",
              }}
            ></Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default DrinkSizeStep;
