import { Button, Typography, Grid, Box } from "@mui/material";

import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";
import HowOftenStepRadioButtons from "./RadioButtons/HowOftenStepRadioButtons";

const drinkNumber = [
  { id: "1", label: "1", value: "1" },
  { id: "2", label: "2", value: "2" },
  { id: "3", label: "3", value: "3" },
  { id: "4", label: "4", value: "4" },
  { id: "5", label: "5", value: "5" },
  { id: "6", label: "6", value: "6" },
];

type FormValues = {
  numberOfDrinks: string;
};

const HowOftenStep = ({
  updateStep,
  previousStep,
  formData,
  setFormData,
  step,
}: any) => {
  const methods = useForm<FormValues>({
    defaultValues: { numberOfDrinks: formData.howOftenStep.numberOfDrinks },
    mode: "onChange",
  });
  const [clickButton, setClickButton] = useState(false);

  const { handleSubmit } = methods;

  const formSubmit = (data: any) => {
    setFormData({
      ...formData,
      howOftenStep: data.target.defaultValue,
    });
    setClickButton(true);
    setTimeout(() => {
      updateStep();
    }, 500);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              height: { xs: "400px", lg: "502px" },
              width: { xs: "100%", lg: "100%" },

              display: "flex",
              background: "#F2F2F2",
              borderTopRightRadius: { xs: 0, lg: "18px" },
              borderBottomRightRadius: { xs: "18px", lg: "18px" },
              borderBottomLeftRadius: { xs: 18, lg: "0" },
              flexDirection: "column",
              padding: { xs: "0 10px 0 10px", lg: "0" },

              justifyContent: "flex-start",
              flexGrow: "1",
            }}
          >
            <Box
              sx={{
                width: "100%",

                padding: { xs: "0 10px 25% 10px", lg: "25px 0 0 0 " },
                margin: { xs: "-70px 0 0 0", lg: "0% 0 0 0" },

                display: { xs: "none", lg: "flex" },
                justifyContent: { xs: "space-between", lg: "flex-start" },
                zIndex: "5",
              }}
            >
              <Button
                onClick={previousStep}
                sx={{
                  height: "2.7rem",
                  borderRadius: "50px",
                  width: { xs: "50%", lg: "7rem" },
                  color: "#FF3A18",

                  background: "transparent",
                  ":hover": {
                    bgcolor: "transparent",
                    color: "#FF3A18",
                  },
                }}
                type="submit"
                variant="text"
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Back
                </Typography>
              </Button>
            </Box>

            <Box
              className="transitions"
              sx={{
                position: "relative",
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box>
                <HowOftenStepRadioButtons
                  name="numberOfDrinks"
                  options={drinkNumber}
                  step={step}
                  onClickButton={formSubmit}
                />
                <Box
                  sx={{
                    padding: { xs: "25px 0 0% 0", lg: "0 0 4% 0 " },
                    margin: { xs: "-0 0 0 0", lg: "0 0 20px 0" },
                    display: { xs: "flex", lg: "none" },
                    justifyContent: {
                      xs: "space-between",
                      lg: "space-between",
                    },
                    zIndex: "5",
                  }}
                >
                  <Button
                    onClick={previousStep}
                    sx={{
                      height: "2.7rem",
                      borderRadius: "50px",
                      width: { xs: "0%", lg: "7rem" },
                      color: "#FF3A18",

                      background: "transparent",
                      ":hover": {
                        bgcolor: "transparent",
                        color: "#FF3A18",
                      },
                    }}
                    type="submit"
                    variant="text"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Manrope",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Back
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default HowOftenStep;
