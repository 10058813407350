import { useState, useEffect, useCallback } from "react";
import DrinkOfChoiceStep from "./DrinkOfChoiceStep";
import Confirm from "./Confirm";
import AgeGate from "./AgeGate";
import GenderStep from "./GenderStep";
import WeightStep from "./WeightStep";
import DoYouMostlyDrinkAlone from "./DoYouMostlyDrinkAloneStep";
import DoYouMostlyDrinkAtHome from "./DoYouMostlyDrinkAtHomeStep";
import DrinkSizeStep from "./DrinkSizeStep";
import DrinkingScheduleStep from "./DrinkingScheduleStep";
import HowOftenStep from "./HowOftenStep";
import PageLayout from "../layout/PageLayout";
import GoalStep from "./GoalsStep";
import ResultsStep from "./ResultsStep";

const FormContainer = () => {
  const [step, setStep] = useState<number>(0);

  const [formProgress, setFormProgress] = useState<number>(0);

  const [formData, setFormData] = useState<any>({
    ageStep: {},
    genderStep: {},
    goalsStep: {},
    weightStep: {},
    aloneOrFriendsStep: {},
    homeOrOutStep: {},
    choiceStep: {},
    drinkSizeStep: {},
    howOftenStep: {},
    drinkScheduleStep: {},
  });

  const updateStep = () => {
    setStep((currentStep) => currentStep + 1);
  };
  const previousStep = () => {
    setStep((currentStep) => currentStep - 1);
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  useEffect(() => {
    // console.log(formData);
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const displayFormSteps = () => {
    switch (step) {
      case 0:
        return (
          <AgeGate
            updateStep={updateStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );

      case 1:
        return (
          <GenderStep
            updateStep={updateStep}
            formData={formData}
            previousStep={previousStep}
            setFormData={setFormData}
            step={step}
          />
        );

      case 2:
        return (
          <GoalStep
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );

      case 3:
        return (
          <WeightStep
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 4:
        return (
          <DoYouMostlyDrinkAlone
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 5:
        return (
          <DoYouMostlyDrinkAtHome
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 6:
        return (
          <DrinkOfChoiceStep
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 7:
        return (
          <DrinkSizeStep
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 8:
        return (
          <HowOftenStep
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );

      case 9:
        return (
          <DrinkingScheduleStep
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );

      case 10:
        return (
          <ResultsStep
            updateStep={updateStep}
            previousStep={previousStep}
            step={step}
          />
        );
      case 11:
        return <Confirm />;
    }
  };

  const updateProgress = useCallback(() => {
    switch (step) {
      case 0:
        setFormProgress(9);

        break;
      case 1:
        setFormProgress(18);
        break;

      case 2:
        setFormProgress(27);
        break;

      case 3:
        setFormProgress(36);
        break;
      case 4:
        setFormProgress(45);
        break;
      case 5:
        setFormProgress(54);
        break;
      case 6:
        setFormProgress(63);
        break;
      case 7:
        setFormProgress(72);
        break;
      case 8:
        setFormProgress(81);
        break;
      case 9:
        setFormProgress(90);
        break;
      case 10:
        setFormProgress(100);
        break;
    }
  }, [step]);

  useEffect(() => {
    updateProgress();
  }, [updateProgress]);

  return (
    <PageLayout
      progressValue={formProgress}
      step={step}
      formData={formData}
      updateStep={updateStep}
      previousStep={previousStep}
    >
      {displayFormSteps()}
    </PageLayout>
  );
};

export default FormContainer;
