import {
  Box,
  FormControl,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";

const WeightButtons = ({ name, options, onClickButton}: any) => {
  const { control } = useFormContext();

  return (
    <Box sx={{ position: "relative" }}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        defaultValue={""}

        render={({ field }) => (
          <FormControl>
            <RadioGroup
              {...field}
              sx={{
                gap: 1,
                minWidth: "100%",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(3, 1fr)",
                  lg: "repeat(1, 1fr)",
                },
              }}
            >
              {options.map((elem: any, index: number) => (
                <Box
                  sx={{
                    display: "flex",
                    background: "#FFF",
                    borderRadius: "9px",
                    width: { xs: "280px", lg: "349px" },
                  }}
                  key={index}

                >
                  <FormControlLabel
                    sx={{ position: "relative", width: "100%", margin: 0 }}
                    value={elem.value}
                    control={
                      <Radio
                        onChange={onClickButton}
                        sx={{
                          fontFamily: "Manrope",
                          color: "#FF3A18",
                          "&.Mui-checked": {
                            color: "#FF3A18",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontFamily: "Manrope", fontWeight: "600" }}
                      >
                        {elem.label}
                      </Typography>
                    }
                  />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default WeightButtons;
