import { Button, Box, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import GenderStepRadioButton from "./RadioButtons/GenderStepRadioButtons";
import "../../styles/transition.css";
import "../../styles/inputStyle.css";
import { useEffect, useState } from "react";

const genderType = [
  { id: "1", label: "Female", value: "Female" },
  { id: "2", label: "Male", value: "Male" },
];

type FormValues = {
  genderName: string;
};

const GenderStep = ({
  updateStep,
  formData,
  setFormData,
  step,
  previousStep,
}: any) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      genderName: formData.genderStep.genderName,
    },
    mode: "onChange",
  });
  const [clickButton, setClickButton] = useState(false);

  const { handleSubmit, setValue, getValues } = methods;

  const formSubmit = (data: any) => {
    setFormData({
      ...formData,
      genderStep: data.target.defaultValue,
    });

    setClickButton(true);

    setTimeout(() => {
      updateStep();
    }, 500);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              height: { xs: "400px", lg: "502px" },
              width: { xs: "100%", sm: "100%", lg: "100%" },
              border: "2px solid #FFF",
              borderTopRightRadius: { xs: 0, lg: "18px" },
              borderBottomRightRadius: { xs: "18px", lg: "18px" },
              borderBottomLeftRadius: { xs: 18, lg: "0" },
              display: "flex",
              background: "#F2F2F2",
              alignItems: "normal",
              flexDirection: "column",
              justifyContent: { xs: "normal", lg: "flex-start" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: { xs: "0 10px 25% 10px", lg: "25px 0 0% 0 " },
                display: { xs: "none", lg: "flex" },
                justifyContent: { xs: "center", lg: "flex-start" },
                zIndex: "5",
              }}
            >
              <Button
                onClick={previousStep}
                sx={{
                  height: "2.7rem",
                  borderRadius: "50px",
                  width: { xs: "50%", lg: "7rem" },
                  color: "#FF3A18",

                  background: "transparent",
                  ":hover": {
                    bgcolor: "transparent",
                    color: "#FF3A18",
                  },
                }}
                type="submit"
                variant="text"
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Back
                </Typography>
              </Button>
            </Box>
            <Box
              className="transitions"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignSelf: "center",
                alignItems: "center",
                justifyContent: { xs: "center", lg: "normal" },
                height: "100%",
              }}
            >
              <GenderStepRadioButton
                name="genderName"
                options={genderType}
                step={step}
                onClickButton={formSubmit}
              />

              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },

                  width: "100%",
                  position: "absolute",
                  bottom: "25px",
                }}
              >
                <Button
                  onClick={previousStep}
                  sx={{
                    height: "2.7rem",
                    width: { xs: "0%", lg: "7rem" },
                    color: "#FF3A18",
                    zIndex: "10",

                    background: "transparent",
                    ":hover": {
                      bgcolor: "transparent",
                      color: "#FF3A18",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default GenderStep;
