import axios from 'axios';

const base = window.location.origin;

// !For local development
// export const baseURL = axios.create({
//     baseURL: 'https://drinks-calculator.aware.org.za/api/v1/aware/users/create',
// });

// ! Production - ensures that the base url is dynamic
export const baseURL = axios.create({
    baseURL: `${base}/api/v1/aware/users/`,
});
