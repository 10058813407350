import { createTheme } from '@mui/material/styles';
export const themeStyles = createTheme({
    typography: {
        fontFamily: 'Manrope, sans-serif',
        h1: {
            fontWeight: 'bold',
        },
        h2: {
            fontWeight: 'bold',
        },
        h3: {
            fontWeight: 'bold',
        },
        h4: {
            fontWeight: 'bold',
        },
        h5: {
            fontWeight: 'bold',
        },
        h6: {
            fontWeight: 'bold',
        },
    },
    palette: {
        primary: {
            main: '#363D49',
        },
        secondary: {
            main: '#FF3A18',
        },
        info: {
            main: '#FFFFFF',
        },
    },
});
