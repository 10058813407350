import { Button, Typography, Grid, Box } from "@mui/material";

import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import GoalsStepButtons from "./RadioButtons/GoalsStepButtons";

const goals = [
  { id: "1", label: "Saving money", value: "saving money" },
  { id: "2", label: "Improving health", value: "improving health" },
  { id: "3", label: "Graduating on time", value: "graduating on time" },
  { id: "4", label: "Finding a job", value: "finding a job" },
];

type FormValues = {
  goals: string;
};

const GoalStep = ({ updateStep, previousStep, formData, setFormData }: any) => {
  const methods = useForm<FormValues>({
    defaultValues: { goals: formData.goalsStep.goals },
  });

  const [clickButton, setClickButton] = useState(false);

  const { handleSubmit } = methods;

  const formSubmit = (data: any) => {
    setFormData({
      ...formData,
      goalsStep: data.target.defaultValue,
    });

    setClickButton(true);
    setTimeout(() => {
      updateStep();
    }, 500);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              height: { xs: "400px", lg: "502px" },
              width: { xs: "100%", sm: "100%", lg: "100%" },
              border: "2px solid #FFF",
              borderTopRightRadius: { xs: 0, lg: "18px" },
              borderBottomRightRadius: { xs: "18px", lg: "18px" },
              borderBottomLeftRadius: { xs: 18, lg: "0" },
              display: "flex",
              background: "#F2F2F2",
              alignItems: "normal",
              flexDirection: "column",
              justifyContent: { xs: "normal", lg: "flex-start" },
            }}
          >
            <Box
              sx={{
                padding: { xs: "0 10px 25% 10px", lg: "25px 0 0% 0 " },
                margin: { xs: "-70px 0 0 0", lg: "0% 0 0 0" },
                width: "100%",

                display: { xs: "none", lg: "flex" },
                justifyContent: { xs: "space-between", lg: "flex-start" },
                zIndex: "5",
              }}
            >
              <Button
                onClick={previousStep}
                sx={{
                  height: "2.7rem",
                  borderRadius: "50px",
                  width: { xs: "50%", lg: "7rem" },
                  color: "#FF3A18",

                  background: "transparent",
                  ":hover": {
                    bgcolor: "transparent",
                    color: "#FF3A18",
                  },
                }}
                type="submit"
                variant="text"
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Back
                </Typography>
              </Button>
            </Box>

            <Box
              className="transitions"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignSelf: "center",
                alignItems: "center",
                justifyContent: { xs: "center", lg: "normal" },
                height: "100%",
                zIndex: 6,
              }}
            >
              <GoalsStepButtons
                name="daysOfDrinking"
                options={goals}
                onClickButton={formSubmit}
              />

              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },

                  width: "100%",
                  position: "absolute",
                  bottom: "25px",
                }}
              >
                <Button
                  onClick={previousStep}
                  sx={{
                    height: "2.7rem",
                    borderRadius: "50px",
                    width: { xs: "0%", lg: "7rem" },
                    color: "#FF3A18",

                    background: "transparent",
                    ":hover": {
                      bgcolor: "transparent",
                      color: "#FF3A18",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default GoalStep;
