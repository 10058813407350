import { Box, FormControl, Radio, RadioGroup, Sheet } from "@mui/joy";
import "../../../styles/transition.css";
import { Controller, useFormContext } from "react-hook-form";

const GenderStepRadioButton = ({ name, options, onClickButton}: any) => {
  const { control } = useFormContext();

  return (
    <Box sx={{ position: "relative" }}>
      <Controller
        name={name}
        control={control}
        defaultValue={""}

        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <RadioGroup
              {...field}
              overlay
              sx={{
                gap: { xs: "20px", lg: 1 },
                mt: 1,
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(2, 1fr)",
                  lg: "repeat(2, 1fr)",
                },
              }}
            >
              {options.map((elem: any, index: number) => (
                <Box                       key={index}
                >
                  <Box>
                    <Sheet
                      component="label"
                      variant="plain"
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                        flexDirection: "column-reverse",
                        borderRadius: "9px",
                        bgcolor: "#FFF",
                        border: "none",
                        width: { xs: "125px", sm: "125px", lg: "125px" },
                        minWidth: "120px",
                        minHeight: { xs: "97px", lg: "97px" },
                        ":hover": {
                          xs: { background: "#FFF" },
                          lg: { background: "#FFE1DC" },
                        },
                      }}
                    >
                      <Radio
                        disableIcon
                        onChange={onClickButton}
                        className="rad"
                        variant="plain"
                        value={elem.value}
                        label={elem.label}
                        slotProps={{
                          label: ({ checked }) => ({
                            sx: {
                              fontFamily: "Manrope",
                              fontWeight: "600",
                              textTransform: "capitilize",

                              position: "relative",
                              display: "inline-grid",
                              color: checked ? "#FF3A18" : "#363D49",

                              textAlign: "center",
                              alignSelf: "center",
                            },
                          }),
                          action: ({ checked }) => ({
                            sx: () => ({
                              ...(checked && {
                                border: "2px solid #FF3A18 !important",
                                bgcolor: "#FFE1DC !important",
                                boxShadow: "0 0 15px 3px #FFE1DC !important",
                              }),
                            }),
                          }),
                        }}
                      />
                      <Box
                        sx={{
                          zIndex: 1,
                          display: "flex",
                          height: "100%",
                          position: "absolute",
                          width: "100%",
                          overflow: "clip",
                          ":hover": {
                            xs: {
                              border: "none",
                              borderRadius: "none",
                              boxShadow: "none",
                            },

                            lg: {
                              border: "2px solid #FF3A18",
                              borderRadius: "9px",
                              boxShadow: "0 0 15px 3px #FFE1DC",
                            },
                          },
                        }}
                      ></Box>
                    </Sheet>
                  </Box>
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default GenderStepRadioButton;
