import './styles/App.css';
import FormContainer from './components/CalculatorForm/FormContainer';
import { themeStyles } from '../src/styles/Theme/ThemeStyles';
import { ThemeProvider } from '@mui/material';



import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

const materialTheme = materialExtendTheme();

function App() {
    return (


        <>
            <MaterialCssVarsProvider
                theme={{ [MATERIAL_THEME_ID]: materialTheme }}
            >
                <JoyCssVarsProvider>
                    <ThemeProvider theme={themeStyles}>
                        {' '}
                        <FormContainer />
                    </ThemeProvider>
                </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
        </>
        
        
        
   
    );
}

export default App;
