import { Button, Typography, Box } from "@mui/material";

import { useForm, FormProvider } from "react-hook-form";

import DoYouMostlyDrinkAloneStepRadioButtons from "./RadioButtons/DoYouMostlyDrinkAloneStepRadioButtons";
import { useState } from "react";

const withWho = [
  { id: "1", label: "Mostly alone", value: "alone" },
  {
    id: "2",
    label: "Mostly with friends",
    value: "friends",
  },
];

type FormValues = {
  aloneOrFriends: string;
};

const DoYouMostlyDrinkAloneStep = ({
  updateStep,
  previousStep,
  formData,
  setFormData,
  step,
}: any) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      aloneOrFriends: formData.aloneOrFriendsStep.aloneOrFriends,
    },
  });

  const [clickButton, setClickButton] = useState(false);

  const { handleSubmit } = methods;

  const formSubmit = (data: any) => {
    setFormData({
      ...formData,
      aloneOrFriendsStep: data.target.defaultValue,
    });
    setClickButton(true);
    setTimeout(() => {
      updateStep();
    }, 500);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Box
            sx={{
              height: { xs: "400px", lg: "502px" },
              width: { xs: "100%", sm: "100%", lg: "100%" },
              border: "2px solid #FFF",
              borderTopRightRadius: { xs: 0, lg: "18px" },
              borderBottomRightRadius: { xs: "18px", lg: "18px" },
              borderBottomLeftRadius: { xs: 18, lg: "0" },
              display: "flex",
              background: "#F2F2F2",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "100%",

                padding: {
                  xs: "0 0 4% 0",
                  sm: "0 0 10px 0",
                  lg: "25px 0 0 0 ",
                },
                margin: {
                  xs: "10px 0 0 0",
                  sm: "100px 0 0 0",
                  lg: "0 0 0 0",
                },
                display: { xs: "none", lg: "flex" },
                justifyContent: { xs: "space-between", lg: "flex-start" },
              }}
            >
              <Button
                onClick={previousStep}
                sx={{
                  height: "2.7rem",
                  borderRadius: "50px",
                  width: { xs: "50%", lg: "7rem" },
                  color: "#FF3A18",

                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
                type="submit"
                variant="text"
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Back
                </Typography>
              </Button>
            </Box>
            <Box
              className="transitions"
              sx={{
                position: "relative",
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <DoYouMostlyDrinkAloneStepRadioButtons
                name="aloneOrFriends"
                options={withWho}
                step={step}
                onClickButton={formSubmit}
              />

              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },

                  width: "100%",
                  position: "absolute",
                  bottom: "25px",
                }}
              >
                <Button
                  onClick={previousStep}
                  sx={{
                    height: "2.7rem",
                    borderRadius: "50px",
                    width: { xs: "0%", lg: "7rem" },
                    color: "#FF3A18",

                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  type="submit"
                  variant="text"
                >
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default DoYouMostlyDrinkAloneStep;
