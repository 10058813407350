import { Box, Button, Typography } from '@mui/joy';
import { CircularProgress } from '@mui/material';
import { apiCreateUser } from '../../api/apiCreateUser';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import Arrow from '../../assets/images/Frame.svg';
import LogoGreen from '../../assets/images/logoGreen.svg';
import '../../styles/transition.css';
import { useEffect } from 'react';
const ResultsStep = ({ updateStep }: any) => {
    const reslutsData = JSON.parse(localStorage.getItem('formData') || '');

    const mutation: any = useMutation((data: any) => {
        const dataStructure = {
            user_id: uuidv4(),
            age: data.ageStep,
            gender: data.genderStep,
            goals: data.goalsStep,
            weight: data.weightStep,
            alone_or_friends: data.aloneOrFriendsStep,
            home_or_out: data.homeOrOutStep,
            drink_choice: data.choiceStep,
            drink_size: data.drinkSizeStep,
            drinks_at_once: data.howOftenStep,
            drinking_frequency: data.drinkScheduleStep,
        };

        return apiCreateUser(dataStructure);
    });

    useEffect(() => {
        if (mutation.isSuccess) {
            updateStep();
        } else {
            return;
        }
    }, [mutation.isSuccess, updateStep]);

    return (
        <Box
            className='transitions'
            sx={{
                overflow: 'hidden',
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: '100%',
                height: { xs: '110%', lg: '100%' },
                marginTop: { xs: '-0', lg: '-0' },
                display: 'flex',
                justifyContent: 'center',
                backdropFilter: 'blur(4px)',
                background: 'rgba(0, 0, 0, 0.50)',
                paddingX: {xs: '10px', lg: '220px'},
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                    height: '280px',
                    width: {xs: '100%', lg:'487px'},
                    justifyContent: 'center',
                    background: '#363D49',
                    borderRadius: '18px',
                    alignSelf: 'center',
                    padding: '50px 0px',
                }}
            >
                <Box>
                    <img src={LogoGreen} alt='/' />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontFamily: 'Manrope',
                            color: '#FFF',
                            fontSize: { xs: '25px', lg: '32px' },
                            fontWeight: '700',
                        }}
                    >
                        The results are in!
                    </Typography>
                </Box>

                <Button
                    sx={{
                        background: '#FF3A18',
                        borderRadius: '77.4px',
                        color: '#FFF',
                        ':hover': {
                            background: 'transparent',
                            border: '1px solid #FFF',
                        },
                    }}
                    onClick={() => {
                        mutation.mutate(reslutsData);
                    }}
                    // onClick={updateStep}
                    type='submit'
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyItems: 'center',
                            gap: '20px',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography
                                sx={{
                                    textTransform: 'none',
                                    fontSize: { xs: '18px', lg: '16px' },
                                }}
                            >
                                View now{' '}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignSelf: 'center',
                                flexGrow: '3',
                            }}
                        >
                            <img src={Arrow} alt='/' />
                        </Box>
                    </Box>
                </Button>
                <Box>
                    {mutation.isLoading && (
                        <CircularProgress sx={{ color: '#fff' }} />
                    )}
                    {mutation.isSuccess && (
                        <Typography sx={{ color: '#fff', mt: 1 }}>
                            Submission successful. Thank you!
                        </Typography>
                    )}
                    {mutation.isError && (
                        <Typography sx={{ color: '#fff', mt: 1 }}>
                            Something went wrong, please try again.
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ResultsStep;
