import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";


const ProgressBar = ({ value }: any) => {

  return (
    <>
      <LinearProgress variant="determinate" value={value} 
      sx={{
        borderRadius: '18px',
        height: '6px',
        [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: "#18181A",
            },
        [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              backgroundColor: "#40A2AD",}

        }}/>
    </>
  );
};


export default ProgressBar;
