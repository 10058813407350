import { Box, FormControl, Radio, RadioGroup, Sheet } from "@mui/joy";
import "../../../styles/transition.css";

import { Controller, useFormContext } from "react-hook-form";

const DoYouMostlyDrinkAtHomeStepRadioButtons = ({
  name,
  options,
  onClickButton,
}: any) => {
  const { control } = useFormContext();

  return (
    <Box sx={{ position: "relative" }}>
      <Controller
        name={name}
        control={control}
        defaultValue={""}

        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <RadioGroup
              {...field}
              overlay
              sx={{
                gap: { xs: "25px", lg: 1 },
                mt: 1,
                display: "grid",

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  lg: "repeat(1, 1fr)",
                },
              }}
            >
              {options.map((elem: any, index: number) => (
                <Sheet
                  component="label"
                  key={index}
                  variant="plain"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                    flexDirection: "column-reverse",
                    borderRadius: "9px",

                    width: { xs: "250px", sm: "300px", lg: "264px" },
                    minWidth: "120px",
                    minHeight: { xs: "97px", lg: "97px" },
                    ":hover": {
                      xs: { background: "#FFF" },
                      lg: { background: "#FFE1DC" },
                    },
                  }}
                >
                  <Radio
                    className="rad"
                    onChange={onClickButton}
                    disableIcon
                    overlay
                    variant="plain"
                    value={elem.value}
                    label={elem.label}
                    slotProps={{
                      label: ({ checked }) => ({
                        sx: {
                          fontFamily: "Manrope",
                          fontWeight: "600",
                          textTransform: "capitilize",

                          position: "relative",
                          display: "inline-grid",
                          color: checked ? "#FF3A18" : "#363D49",
                          py: 1,
                          textAlign: "center",
                          alignSelf: "center",
                        },
                      }),
                      action: ({ checked }) => ({
                        sx: (theme) => ({
                          ...(checked && {
                            border: "2px solid #FF3A18",
                            bgcolor: "#FFE1DC",
                            boxShadow: "0 0 15px 3px #FFE1DC",
                          }),
                        }),
                      }),
                    }}
                  />

                  <Box
                    sx={{
                      zIndex: 1,
                      display: "flex",
                      height: "100%",
                      position: "absolute",
                      width: "100%",
                      overflow: "clip",
                      ":hover": {
                        xs: {
                          border: "none",
                          borderRadius: "none",
                          boxShadow: "none",
                        },

                        lg: {
                          border: "2px solid #FF3A18",
                          borderRadius: "9px",
                          boxShadow: "0 0 15px 3px #FFE1DC",
                        },
                      },
                    }}
                  ></Box>
                </Sheet>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default DoYouMostlyDrinkAtHomeStepRadioButtons;
